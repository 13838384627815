<template>
    <div class="chart-box clickable" >
        <canvas :id="'fi-bars-' + id"></canvas>
    </div>
</template>

<script>
    import {aggregateConsPosDataByCategory} from "../../utils/data.utils";
    import {currencyFormatter} from "../../utils/format.utils";
    import {sortBy} from "../../utils/data.utils";
    import Chart from 'chart.js'
    import ChartDataLabels from 'chartjs-plugin-datalabels';

    export default {
        name: "FixedIncomeBars",
        props: [
            'data', 'viewBy', 'id'
        ],
        mounted() {
            let ctx = document.getElementById('fi-bars-' + this.id);
            ctx.height = 170;
            this.chart = new Chart(ctx, {
                plugins: [ChartDataLabels],
                type: 'horizontalBar',
                data: {
                    labels: this.chartFormattedLabels,
                    datasets: [{
                        label: 'USD Value',
                        data: this.chartFormattedData,
                        backgroundColor: this.chartFormattedColors.map(x => x),
                        borderColor: this.chartFormattedColors.map(x => x),
                        barPercentage: .8
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        display: false
                    },

                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display:false,
                                beginAtZero: true
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                display: false
                            }
                        }]
                    },

                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let currentValue = dataset.data[tooltipItem.index];
                                let indice = tooltipItem.index;
                                return  data.labels[indice] +': ' + currencyFormatter.format(currentValue);
                            }
                        }
                    },

                    plugins: {
                        datalabels: {
                            clamp: true,
                            anchor: 'end',
                            align: function(context){
                                let maxValue = Math.max.apply(null, context.dataset.data);
                                let midPoint = maxValue / 2;
                                return context.dataset.data[context.dataIndex] > midPoint ? 'start' : 'end'
                            },
                            color: function(context){
                                let maxValue = Math.max.apply(null, context.dataset.data);
                                let midPoint = maxValue / 2;
                                return context.dataset.data[context.dataIndex] > midPoint ? 'white' : 'black'
                            },
                            formatter: function (value, context){

                                let data = context.dataset.data;
                                let totalValue = 0;
                                data.forEach(record => {
                                    totalValue += parseFloat(record)
                                });

                                let percent = (value / totalValue * 100).toFixed(2) + '%';
                                return currencyFormatter.format(value) + ' (' + percent +  ')'
                            }
                        }
                    }
                }
            });

            ctx.onclick = (evt) => {
                let activePoints = this.chart.getElementAtEvent(evt);

                // If they click directly on a bar
                if (activePoints[0]){

                    // Set the filter
                    let activeIdx = activePoints[0]['_index'];
                    let label = this.chart.data.labels[activeIdx];
                    this.$store.dispatch('setFixedIncomeChartFilter', label);

                    // If they clicked anywhere but a bar
                } else {

                    let mousePoint = Chart.helpers.getRelativePosition(evt, this.chart.chart);
                    let minXPointOnChart = this.chart.scales['x-axis-0'].left;
                    let xClickPoint = mousePoint.x;

                    // If they click in the chart area (but not on a bar)
                    if (xClickPoint >= minXPointOnChart) {
                        // Set the chart filter back to null
                        this.$store.dispatch('setFixedIncomeChartFilter', null);

                    // If they clicked in the axis label area, assume they meant to click on a label
                    } else {
                        let activeIdx = this.chart.scales['y-axis-0'].getValueForPixel(mousePoint.y);
                        let label = this.chart.data.labels[activeIdx];
                        this.$store.dispatch('setFixedIncomeChartFilter', label);
                    }
                }
            }
        },
        watch: {
            aggregateData: function () {

                this.chart.data.labels = this.chartFormattedLabels;
                this.chart.data.datasets[0].data = this.chartFormattedData;
                this.chart.data.datasets[0].backgroundColor = this.chartFormattedColors.map(x => x);
                this.chart.update();
                this.chart.resize()
            },

            fixedIncomeChartFilter: function (){
                if (this.fixedIncomeChartFilter !== null){
                    let activeIdx = this.chart.data.labels.indexOf(this.fixedIncomeChartFilter);
                    // Change the colors
                    let backgroundColors = this.chart.data.datasets[0].backgroundColor;
                    for (let i = 0; i < backgroundColors.length; i++){
                        if (i !== activeIdx) backgroundColors[i] = 'lightgray';

                    }

                    backgroundColors[activeIdx] = this.chartFormattedColors[activeIdx];

                } else {

                    // Change the colors back
                    let backgroundColors = this.chart.data.datasets[0].backgroundColor;
                    for (let i = 0; i < backgroundColors.length; i++) {
                        backgroundColors[i] = this.chartFormattedColors[i];

                    }
                }

                this.chart.update()
            }
        },
        computed: {
            aggregateData(){

                let newData = this.data.map((x) => x);
                return aggregateConsPosDataByCategory(newData, this.viewBy).filter(record => {
                    return record.usd_amount !== 0;
                })
            },

            totalValue(){

                let totalValue = 0;

                this.aggregateData.forEach(record => {
                    totalValue += record.usd_amount
                });

                return totalValue

            },

            sortedData(){
                let newData = this.aggregateData.map(x => x);
                let sortFunc = sortBy('sorting', {name: 'usd_amount', reverse: true});
                return newData.sort(sortFunc)
            },

            chartFormattedLabels(){
                let labels = [];
                this.sortedData.forEach(record => {
                    labels.push(record.aggBy)
                });
                return labels
            },

            chartFormattedColors(){
                let colors = [];
                this.sortedData.forEach(record => {
                    colors.push(record.color)
                });
                return colors
            },

            chartFormattedData(){
                let data = [];
                this.sortedData.forEach(record => {
                    data.push((record.usd_amount || 0).toFixed(2))
                });
                return data
            },

            fixedIncomeChartFilter(){
                return this.$store.getters.fixedIncomeChartFilter
            }
        }
    }
</script>

<style scoped>

</style>