<template>
    <div>
        <v-container class="a-container">
            <div :class="mobileCentering" class="display-1 primary-text font-weight-bold mb-2 mt-3">Fixed Income</div>
            <v-divider></v-divider>
             <v-row v-if="!isMobile" class="mt-3" :justify="mobileFilterCentering">
                <v-col cols="12" sm="3">
                    <v-select
                        v-model="filters.viewBy"
                        label="View Fixed Income By"
                        :items="viewByOptions"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="3">
                    <clearable-chips-selector
                        label="Group"
                        v-model="selectedGroupFilter"
                        :items="groups"
                    >
                    </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="3">
                    <clearable-chips-selector
                        label="Manager"
                        v-model="selectedManagerFilter"
                        :items="managers"
                    >
                    </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="3">
                    <clearable-chips-selector
                        :items="accountIds"
                        label="Account"
                        v-model="selectedAccountFilter"
                    >
                    </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <clearable-chips-selector
                        :items="banks"
                        label="Custodian"
                        v-model="selectedBankFilter"
                    >
                    </clearable-chips-selector>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    lg="6"
                >
                    <fixed-income-donut-card
                        :data="filteredConsPosFI"
                        :view-by="filters.viewBy"
                        id="fi-donut"
                        :clickable-chart="true"
                    ></fixed-income-donut-card>
                </v-col>
                <v-col
                    cols="12"
                    lg="6"
                >
                    <fixed-income-bar-card
                        :data="filteredConsPosFI"
                        :view-by="filters.viewBy"
                        id="fi-bars"
                    >

                    </fixed-income-bar-card>
                </v-col>
                <v-col cols="12">
                    <fixed-income-extended-table-card
                        :data="filteredConsPosFI"
                        :view-by="filters.viewBy"
                        id="fi-table"
                    >
                    </fixed-income-extended-table-card>
                </v-col>
            </v-row>
        </v-container>
        <mobile-filter-pane>
            <template v-slot:filters>
                <v-col cols="12">
                    <v-select
                        v-model="filters.viewBy"
                        label="View Fixed Income By"
                        :items="viewByOptions"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        label="Group"
                        v-model="selectedGroupFilter"
                        :items="groups"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        label="Manager"
                        v-model="selectedManagerFilter"
                        :items="managers"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        :items="accountIds"
                        label="Account"
                        v-model="selectedAccountFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        :items="banks"
                        label="Custodian"
                        v-model="selectedBankFilter"
                    >
                    </clearable-chips-selector>
                </v-col>
            </template>
        </mobile-filter-pane>
    </div>
</template>

<script>
    import FixedIncomeDonutCard from '../../components/reports/FixedIncomeDonutCard'
    import FixedIncomeBarCard from '../../components/reports/FixedIncomeBarCard'
    import FixedIncomeExtendedTableCard from '../../components/reports/FixedIncomeExtendedTableCard'
    import ClearableChipsSelector from '../../components/base/filters/ClearableChipsSelector'
    import {mapState, mapGetters} from 'vuex'
    import MobileFilterPane from '../../components/base/filters/MobileFilterPane'


    export default {
        name: "FixedIncome",
        components: {
            ClearableChipsSelector, FixedIncomeDonutCard,
            FixedIncomeBarCard, FixedIncomeExtendedTableCard,
            MobileFilterPane
        },
        data(){
            return {
                filters: {
                viewBy: 'sub_category'
            },
            viewByOptions: [
                {text: 'Rating', value: 'credit_rating_det'},
                {text: 'Maturity', value: 'maturity'},
                {text: 'SubCategory', value: 'sub_category'},
                {text: 'Currency', value: 'currency'},
                {text: 'Geolocation', value: 'geolocation'},

            ]

            }
        },
        mounted(){
            this.$store.dispatch('setFixedIncomeChartFilter', null);
        },
        computed: {
            isMobile(){
                return this.$vuetify.breakpoint.mdAndDown
            },

            mobileCentering(){
                return this.isMobile ? 'text-center' : ''
            },

            mobileFilterCentering(){
                return this.isMobile ? 'center' : null
            },
            filteredConsPosFI(){

                return this.filteredConsPos.map(x => x).filter(record => {
                    return record.category.toLowerCase() === 'fixed income'
                }).filter(x => {
                    return x.nominal_amount !== 0
                })

            },
            ...mapState(['groupFilter', 'managerFilter', 'accountFilter', 'bankFilter']),
            ...mapGetters(['accountIds', 'groups', 'managers', 'filteredConsPos', 'banks']),
            selectedGroupFilter: {
                set(groups){
                    this.$store.commit('setGroupFilter', groups)
                },
                get(){
                    return this.groupFilter
                }
            },
            selectedManagerFilter: {
                set(managers){
                    this.$store.commit('setManagerFilter', managers)
                },
                get(){
                    return this.managerFilter
                }
            },
            selectedAccountFilter: {
                set(accounts){
                    this.$store.commit('setAccountFilter', accounts)
                },
                get(){
                    return this.accountFilter
                }
            },
            selectedBankFilter: {
                set(banks){
                    this.$store.commit('setBankFilter', banks)
                },
                get(){
                    return this.bankFilter
                }
            },
        }
    }
</script>

<style scoped>

</style>