<template>
    <div class="chart-box" :class="clickable ? 'clickable' : ''">
        <canvas :id="'fixed-income-chart-' + id">
        </canvas>
    </div>
</template>

<script>
    import {aggregateConsPosDataByCategory, sortBy} from "../../utils/data.utils";
    import {currencyFormatter} from "../../utils/format.utils";
    import Chart from 'chart.js';

    export default {
        name: "FixedIncomeDonut",
        props: ['data', 'viewBy', 'id', 'clickable'],
        mounted () {
            let ctx = document.getElementById('fixed-income-chart-' + this.id);
            ctx.height = 100;
            this.chart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: this.chartFormattedLabels,
                    datasets: [{
                        label: 'USD Value',
                        data: this.chartFormattedData,
                        backgroundColor: this.chartFormattedColors.map((x) => x),
                        borderColor: 'white'
                    }]
                },
                options: {
                    cutoutPercentage: 70,
                    responsive: true,
                    maintainAspectRatio: true,

                    legend: {
                        display: false
                    },

                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                let total = meta.total;
                                let currentValue = dataset.data[tooltipItem.index];
                                let indice = tooltipItem.index;
                                let percent = (currentValue / total * 100).toFixed(2) + '%';
                                return  data.labels[indice] +': ' + currencyFormatter.format(currentValue) + ' (' + percent + ')';
                            }
                        }
                    }
                }
            });

            if (this.clickable){
                ctx.onclick = (evt) => {
                    let activePoints = this.chart.getElementAtEvent(evt);
                    if (activePoints[0]){

                        // Set the filter
                        let activeIdx = activePoints[0]['_index'];
                        let label = this.chart.data.labels[activeIdx];
                        this.$store.dispatch('setFixedIncomeChartFilter', label);

                    } else {

                        // Set it back
                        this.$store.dispatch('setFixedIncomeChartFilter', null);

                    }
                }
            }
        },

        watch: {
            aggregateData: function () {
                this.chart.data.labels = this.chartFormattedLabels;
                this.chart.data.datasets[0].data = this.chartFormattedData;
                this.chart.data.datasets[0].backgroundColor = this.chartFormattedColors.map((x) => x);
                this.chart.update()
            },

            fixedIncomeChartFilter: function (){
                if (this.fixedIncomeChartFilter !== null){
                    let activeIdx = this.chart.data.labels.indexOf(this.fixedIncomeChartFilter);
                    // Change the colors
                    let backgroundColors = this.chart.data.datasets[0].backgroundColor;
                    for (let i = 0; i < backgroundColors.length; i++){
                        if (i !== activeIdx) backgroundColors[i] = 'lightgray';

                    }

                    backgroundColors[activeIdx] = this.chartFormattedColors[activeIdx];

                } else {

                    // Change the colors back
                    let backgroundColors = this.chart.data.datasets[0].backgroundColor;
                    for (let i = 0; i < backgroundColors.length; i++) {
                        backgroundColors[i] = this.chartFormattedColors[i];

                    }
                }

                this.chart.update()
            }
        },

        computed: {
            aggregateData(){

                let newData = this.data.map((x) => x);
                newData = aggregateConsPosDataByCategory(newData, this.viewBy).filter(record => {
                    return record.usd_amount !== 0;
                });

                let sortFunc = sortBy('sorting', {name: 'usd_amount', reverse:true});

                return newData.sort(sortFunc)

            },

            chartFormattedLabels(){
                let labels = [];
                this.aggregateData.forEach(record => {
                    labels.push(record.aggBy)
                });
                return labels
            },

            chartFormattedColors(){
                let colors = [];
                this.aggregateData.forEach(record => {
                    colors.push(record.color)
                });
                return colors
            },

            chartFormattedData(){
                let data = [];
                this.aggregateData.forEach(record => {
                    data.push((record.usd_amount || 0).toFixed(2))
                });
                return data
            },

            fixedIncomeChartFilter(){
                return this.$store.getters.fixedIncomeChartFilter
            }
        }
    }
</script>

<style scoped>

</style>