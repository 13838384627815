import { render, staticRenderFns } from "./FixedIncomeDonut.vue?vue&type=template&id=853d8630&scoped=true&"
import script from "./FixedIncomeDonut.vue?vue&type=script&lang=js&"
export * from "./FixedIncomeDonut.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "853d8630",
  null
  
)

export default component.exports