<template>
    <div>
        <v-data-table
            :items="formattedTableData"
            :headers="tableHeaders"
            dense
            hide-default-footer
            disable-sort
            disable-pagination
            :mobile-breakpoint="0"
            no-data-text="No positions found."
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ item.aggBy }}</td>
                    <td class="text-right">{{ item.usd_amount }}</td>
                    <td class="text-right">{{ item.percent }}</td>
                    <td class="text-right">
                        <v-icon
                            v-if="item.color"
                            :color="item.color"
                            small
                        >mdi-circle
                        </v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { currencyFormatter } from "../../utils/format.utils";
    import {aggregateConsPosDataByCategory, sortBy} from "../../utils/data.utils";

    export default {
        name: "FixedIncomeTable",
        props: ['data', 'viewBy', 'id'],
        data() {
            return {
                viewByNameMap: {
                    credit_rating_det: 'Rating',
                    sub_category: 'Subcategory',
                    maturity: 'Maturity'
                }
            }
        },
        computed: {

            formattedViewBy() {
                return this.viewByNameMap[this.viewBy]
            },

            tableHeaders() {
                return [
                    {text: this.formattedViewBy, value: 'aggBy'},
                    {text: 'Value', value: 'usd_amount', align: 'right'},
                    {text: '%', value: 'percent', align: 'right'},
                    {text: '', value: 'color'}
                ]
            },

            aggregatedData(){
                let newData = aggregateConsPosDataByCategory(this.data.map(x => x), this.viewBy);
                let sortFunc = sortBy('sorting', {name: 'usd_amount', reverse: true});
                return newData.sort(sortFunc)
            },

            formattedData(){
                let formatter = currencyFormatter;
                let formattedData = [];

                this.aggregatedData.forEach(v => formattedData.push({
                    'aggBy': v['aggBy'],
                    'usd_amount': formatter.format(v['usd_amount']),
                    'percent': (v['percent'] * 100).toFixed(2) + '%',
                    'color': v['color']
                }));

                return formattedData
            },

            totalAssets(){

                let totalAssets = 0;
                this.aggregatedAssets.forEach(record => {
                    totalAssets += record.usd_amount
                });

                return totalAssets;
            },

            formattedTableData() {

                return this.formattedData
            },

            totalNetValue(){

                return this.totalAssets + this.totalLiab

            }
        }
    }
</script>

<style scoped>
    >>>.v-data-table-header-mobile__wrapper{
        display: none;
    }

    .legend-chip{
        height: 1rem;
    }

    .total-bar{
        border-bottom: 1px solid black !important;
    }

    .total-field{
        font-weight: bold;
    }
</style>