<template>
    <report-summary-card
        report-title="Fixed Income Share %"
        :loading="loading"
        :report-link="link"
    >
        <template v-slot:chart>
            <fixed-income-donut
                :data="data"
                :view-by="viewBy"
                :id="id"
                :clickable="clickableChart"
                class="mt-4"
            ></fixed-income-donut>
        </template>
        <template v-slot:table>
            <div class="fixed-income-table mt-5">
                <fixed-income-table
                    :data="data"
                    :view-by="viewBy"
                    id="fi-table"
                ></fixed-income-table>
            </div>
        </template>
    </report-summary-card>
</template>

<script>
    import {currencyFormatter} from "../../utils/format.utils";
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import FixedIncomeDonut from './FixedIncomeDonut';
    import FixedIncomeTable from './FixedIncomeTable'

    export default {
        name: "FixedIncomeDonutCard",
        props: [
            'link', 'data', 'viewBy', 'id', 'clickableChart'
        ],
        components: {
            ReportSummaryCard, FixedIncomeDonut, FixedIncomeTable
        },
        data() {
            return {

            }
        },
        computed: {
            loading() {
                return this.$store.getters.consPosLoading
            },

            totalValue(){

                let totalValue = 0;
                this.data.forEach(record => {
                    totalValue = totalValue + record.usd_amount
                });

                return currencyFormatter.format(totalValue)

            },
        }
    }
</script>

<style scoped>
    .fixed-income-table{
        max-height: 17rem;
        overflow-y: scroll;
        border: 1px solid lightgray;
    }
</style>