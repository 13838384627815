a<template>
    <report-summary-card
        report-title="Fixed Income Table Details"
        :loading="loading"
        report-link=""
        :show-search="false"
        @search="searchPositions"
        show-export
        export-file-name="fixed_income_table_details.csv"
        :export-data="exportData"
    >
        <template v-slot:table>
            <div
                @mouseleave="showTooltip = false"
            >
                <v-data-table
                    :headers="tableHeaders"
                    :loading="loading"
                    :items="formattedSortedTableData"
                    :search="searchText"
                    no-data-text="No positions found."
                    :mobile-breakpoint="0"
                    :custom-sort="customSort"
                    :options.sync="options"
                    fixed-header
                    :height="formattedSortedTableData.length > 10 ? 525 : 50 + formattedSortedTableData.length * 52.5"
                >
                    <template v-slot:item="{item, headers}">
                        <tr
                            @mouseenter="showTooltip = true"
                        >
                            <td
                                :class="getColumnClasses(header, item)"
                                :key="index"
                                v-for="(header, index) in headers"
                                @click="getToolTipBreakdown(item.macro_category, item.position_name)"
                                @mouseover="getToolTipBreakdown(item.macro_category, item.position_name)"
                                @mousemove="updateToolTipPosition"
                            >
                                {{ item[header.value] }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
            <extended-table-tool-tip
                v-if="showTooltip"
                :data="tooltipData"
                :position-x="positionX"
                :position-y="positionY"
            ></extended-table-tool-tip>
        </template>
    </report-summary-card>
</template>

<script>
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import { currencyFormatter } from "../../utils/format.utils";
    import {sortBy, disaggForTooltip} from '../../utils/data.utils';
    import ExtendedTableToolTip from './ExtendedTableToolTip'


    export default {
        name: "FixedIncomeExtendedTableCard",
        props: [
            'data', 'viewBy', 'id'
        ],
        components: {
            ReportSummaryCard, ExtendedTableToolTip
        },
        data() {
            return {
                options: {},
                viewByNameMap: {
                    credit_rating_det: 'Rating',
                    sub_category: 'Subcategory',
                    maturity: 'Maturity'
                },
                searchText: '',
                tableHeaders: [
                        {text: 'Subcategory', value: 'sub_category', width: "150"},
                        {text: 'Position', value: 'position_name', width: "400"},
                        {text: 'Currency', value: 'currency', width: 140},
                        {text: 'Credit Rating', value: 'credit_rating_det', width: "160"},
                        {text: 'Coupon/Div', value: 'coupon_div', align: 'right'},
                        {text: 'YTM', value: 'ytm', align: 'right'},
                        {text: 'YTC', value: 'ytw', align: 'right'},
                        {text: 'Maturity Yr', value: 'maturity_year'},
                        {text: 'Geolocation', value: 'geolocation'},
                        {text: 'P/L', value: 'profit_loss', align: 'right'},
                        {text: 'Mkt Price', value: 'price', align: 'right'},
                        {text: 'Nominal Amount', value: 'nominal_amount', align: 'right'},
                        {text: 'Mkt Value', value: 'usd_amount', align: 'right'},
                        {text: 'Accrued Interest', value: 'accrued', align: 'right'},
                        {text: '% of Portfolio', value: 'percent', align: 'right'}
                    ],
                showTooltip: false,
                tooltipData: [],
                positionX: 0,
                positionY: 0
            }
        },
        watch: {
            viewBy: function () {
                this.options.sortBy = [];
                this.options.sortDesc = [];
            }
        },
        methods: {
            searchPositions(searchText){
                this.searchText = searchText
            },

            getToolTipBreakdown(macroCategory, positionName){
                this.updateToolTipPosition();
                this.tooltipData = disaggForTooltip(macroCategory, positionName, this.data.map(x=>x));
            },
            updateToolTipPosition(){
                let posY = event.clientY;
                posY -= 32; // Table header
                posY -= (24 * this.tooltipHeight); // table body height
                posY -= 15; // Buffer
                this.positionY = posY;
                this.positionX = event.clientX;
            },
            getHeaderClass(header){
                return  header.align ? `text-${header.align}` : ''
            },
            getPLColorClass(header, item){

                if(header.value === 'profit_loss'){
                    let profitLoss = item.profit_loss;
                    return profitLoss[0] === '-' ? 'negative-pl' : 'positive-pl'
                }

                return ''

            },
            getColumnClasses(header, item){

                let headerClass = this.getHeaderClass(header);
                let PLColorClass = this.getPLColorClass(header, item);
                return [headerClass, PLColorClass].join(' ');

            },
            customSort(items, index, isDescending) {
                if (index.length === 0) {
                    return items
                }
                const isDesc = !isDescending[0];  // reversed so that ascending sort is really descending
                const sortField = index[0];
                const keys = Object.keys(this.formattedSortedTableData[0] || {});
                const hasRawField = keys.includes(sortField + '_raw');
                let sortFunc;
                if (hasRawField) {
                    const rawField = sortField + '_raw';
                    if (!isDesc) {
                        sortFunc = sortBy(rawField);
                    } else {
                        sortFunc = sortBy({name: rawField, reverse: true})
                    }
                } else {
                    if (!isDesc) {
                        sortFunc = sortBy(sortField);
                  } else {
                        sortFunc = sortBy({name: sortField, reverse: true})
                  }
                }

                return items.sort(sortFunc)
            }
        },
        computed: {
             loading() {
                return this.$store.getters.consPosLoading
            },
            tooltipHeight(){
                return this.tooltipData.length;
            },
            formattedViewBy() {
                return this.viewByNameMap[this.viewBy]
            },

            totalValue(){
                let totalValue = 0;
                this.data.forEach(record => {
                    totalValue += record.usd_amount
                });

                return totalValue
            },

            chartFilteredData(){
                if (this.fixedIncomeChartFilter !== null){

                    return this.data.filter(record => {
                        return record[this.viewBy] === this.fixedIncomeChartFilter
                    })
                } else {
                    return this.data
                }
            },

            aggregatedData(){

                const result = [...this.chartFilteredData.reduce((r, o) => {
                    const key = o.position_name;

                    const item = r.get(key) || Object.assign({}, o, {
                        quantity: 0,
                        nominal_amount: 0,
                        price: 0,
                        cost_price: 0,
                        usd_amount: 0,
                        coupon_div: 0,
                        ytm: 0,
                        ytw: 0,
                        accrued: 0,
                        sort_order_sub_category: 1
                    });

                    item.quantity += o.quantity;
                    item.nominal_amount += o.nominal_amount;
                    item.price += o.price * o.quantity;
                    item.cost_price += o.cost_price * o.quantity;
                    item.usd_amount += o.usd_amount;
                    item.coupon_div += o.coupon_div * o.quantity;
                    item.ytm += o.ytm * o.quantity;
                    item.ytw += o.ytw * o.quantity;
                    item.accrued += o.accrued;
                    item.sort_order_sub_category = o.sorting.sub_category;

                    return r.set(key, item);

                }, new Map).values()];

                result.forEach(record => {
                    record.cost_price = record.cost_price / record.quantity;
                    record.price = record.price / record.quantity;
                    record.coupon_div = record.coupon_div / record.quantity;
                    record.ytm = record.ytm / record.quantity;
                    record.ytw = record.ytw / record.quantity;
                    record.profit_loss = record.cost_price === 0 ? 0 : ((record.price - record.cost_price) / record.cost_price) || 0
                });


                return result
            },

            sortedTableData(){
                let newData = this.aggregatedData.map((x) => x);
                let sortFunc = sortBy(
                    'sort_order_sub_category', 'sub_category',
                    {name: 'usd_amount', reverse: true});
                return newData.sort(sortFunc)

            },

            formattedSortedTableData(){
                 let newData = [];
                 this.sortedTableData.forEach(record => {

                     let percent = record.usd_amount / this.totalValue * 100;
                     let newRecord = {
                         macro_category: record.macro_category,
                         sub_category: record.sub_category,
                         position_name: record.position_name,
                         credit_rating_det: record.credit_rating_det,
                         currency: record.currency,
                         coupon_div: (record.coupon_div * 100).toFixed(2) + '%',
                         coupon_div_raw: record.coupon_div,
                         ytm: record.ytm === 0 ? '' : (record.ytm * 100).toFixed(2) + '%',
                         ytm_raw: record.ytw,
                         ytw: record.ytw === 0 ? '' : (record.ytw * 100).toFixed(2) + '%',
                         ytw_raw: record.ytw,
                         maturity_year: record.maturity_year,
                         geolocation: record.geolocation,
                         nominal_amount: Number(record.nominal_amount).toLocaleString(),
                         nominal_amount_raw: record.nominal_amount,
                         cost_price: currencyFormatter.format(record.cost_price),
                         cost_price_raw: record.cost_price,
                         price: currencyFormatter.format(record.price),
                         price_raw: record.price,
                         profit_loss: (record.profit_loss * 100).toFixed(2) + '%',
                         profit_loss_raw: record.profit_loss,
                         usd_amount: currencyFormatter.format(record.usd_amount),
                         usd_amount_raw: record.usd_amount,
                         percent: percent.toFixed(2) + '%',
                         percent_raw: percent,
                         quantity: record.quantity,
                         accrued: currencyFormatter.format(record.accrued),
                         accrued_raw: record.accrued
                     };

                     newRecord[this.viewBy] = record[this.viewBy];
                     newData.push(newRecord)
                 });

                return newData

            },

            fixedIncomeChartFilter(){
                return this.$store.getters.fixedIncomeChartFilter
            },

            exportData() {
                let exportData = []
                let data = this.chartFilteredData.slice()
                data.forEach(o => {
                    const newObject = {
                        'SubCategory': o.sub_category,
                        'ISIN/CUSIP': o.isin,
                        'Position Name': o.position_name,
                        'Account': o.portfolio,
                        'Currency': o.currency,
                        'Credit Rating': o.credit_rating_det,
                        'Coupon/Div': (o.coupon_div || 0).toFixed(4),
                        'YTM': (o.ytm || 0).toFixed(4),
                        'YTC': (o.ytw || 0).toFixed(4),
                        'Maturity Yr': o.maturity_year,
                        'GeoLocation': o.geolocation,
                        'P/L': (o.profit_loss || 0).toFixed(4),
                        'Market Price': (o.price || 0).toFixed(2),
                        'Nominal Amount': o.nominal_amount.toFixed(2),
                        'Market Value': o.usd_amount.toFixed(2),
                        'Accrued Interest': (o.accrued || 0).toFixed(2),
                        '% of Portfolio': (o.usd_amount / this.totalValue).toFixed(4)
                    }
                    exportData.push(newObject)
                })

                return exportData
            }
        }
    }
</script>

<style scoped>
.positive-pl{
    color: #4CAF50;
}

.negative-pl{
    color: #F44336;
}

>>> .v-data-table-header th.desc .v-data-table-header__icon {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

>>> .v-data-table-header th.asc .v-data-table-header__icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

>>> .v-data-table-header th:hover:not(.desc):not(.asc) .v-data-table-header__icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
</style>